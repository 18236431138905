import {
  useEffect,
  useRef,
  useState
} from 'react'
import '../styles/portfolio.css'

function Home() {

  const [offset, setOffset] = useState(0)
  const [hideTechnologies, setHideTechnologies] = useState(true)

  const skillsRef = useRef()

  useEffect(() => {
    document.title = 'Ugo H.'

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        })
      })
    })

    const onScroll = () => setOffset(window.scrollY)
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, {passive: true})

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (offset >= skillsRef.current.offsetTop && hideTechnologies === true)
      setHideTechnologies(false)
  }, [offset, hideTechnologies])

  return (
    <div className="app">
      <div id="content">
        <div id="intro">
          <span id="intro-title" ref={skillsRef}>Ugo H.</span>
          <span id="intro-subtitle">Développeur Mobile et Web</span>
          <a id="intro-contact" href="mailto:ugohbg+portfolio@gmail.com">Me contacter</a>
        </div>
        <div id="skills">
          <div class="section-header">
            <span class="section-title">Mes compétences</span>
          </div>
          <div id="skills-content">
            <div class="skills-col">
              <p class="skills-col-title">Langages</p>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/js-logo.png')} alt="JavaScript" class="skill-image" />
                <span class="skill-name">JavaScript</span>
              </div>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/html-logo.png')} alt="HTML" class="skill-image" />
                <span class="skill-name">HTML</span>
              </div>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/css-logo.png')} alt="CSS" class="skill-image" />
                <span class="skill-name">CSS</span>
              </div>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/php-logo.png')} alt="PHP" class="skill-image" />
                <span class="skill-name">PHP</span>
              </div>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/sql-logo.png')} alt="SQL" class="skill-image" />
                <span class="skill-name">SQL</span>
              </div>
              <p class="skills-col-title">Bibliothèque</p>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/react-logo.png')} alt="React.js" class="skill-image" />
                <span class="skill-name">React</span>
              </div>
            </div>
            <div class="skills-col">
              <p class="skills-col-title">Frameworks</p>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/react-logo.png')} alt="React Native" class="skill-image" />
                <span class="skill-name">React Native</span>
              </div>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/laravel-logo.png')} alt="Laravel" class="skill-image" />
                <span class="skill-name">Laravel</span>
              </div>
              <p class="skills-col-title">Cloud</p>
              <div class={"skill" + (hideTechnologies ? " skill-hidden" : "")}>
                <img src={require('../images/firebase-logo.png')} alt="Firebase" class="skill-image" />
                <span class="skill-name">Firebase</span>
              </div>
            </div>
          </div>
        </div>
        <div id="experience">
          <div class="section-header">
            <span class="section-title">Mon expérience</span>
          </div>
          <div class="experience">
            <div class="experience-image-container">
              <img src={require('../images/agence-mna.png')} alt="Agence MNA" class="experience-image" />
            </div>
            <div class="experience-content">
              <p class="experience-name">
                Marc Nicolas Architectures
              </p>
              <p class="experience-description">
                Refonte complète du site web en utilisant des technologies modernes 
              </p>
              <p class="experience-keywords">
                Full-Stack, Laravel
              </p>
              <a href="https://agence-mna.com" target="_blank" rel="noreferrer" class="experience-link">Lien vers le site</a>
            </div>
          </div>
          <div class="experience-separator"></div>
          <div class="experience">
            <div class="experience-image-container">
              <img src={require('../images/switup.png')} alt="Switup" class="experience-image" />
            </div>
            <div class="experience-content">
              <p class="experience-name">
                Switup
              </p>
              <p class="experience-description">
                Application mobile cross-platform pour suivre ses entraînements sportifs<br />
                Inclut le suivi de la nutrition, des mensurations et des objectifs sportifs.
              </p>
              <p class="experience-keywords">
                Mobile, React Native, JavaScript
              </p>
              <div class="experience-links">
                <a href="https://apps.apple.com/tt/app/switup-musculation-fitness/id1551956246" target="_blank" rel="noreferrer" class="experience-link">iOS</a>
                <a href="https://play.google.com/store/apps/details?id=com.switup" target="_blank" rel="noreferrer" class="experience-link">Android</a>
              </div>
            </div>
          </div>
        </div>
        <footer>
        </footer>
      </div>
      <nav id="sidebar-nav">
        <div id="links">
          <a href="#intro">Présentation</a>
          <a href="#skills">Compétences</a>
          <a href="#experience">Expérience</a>
        </div>
        <div id="logos">
          <a href="https://github.com/ugohr">
            <img src={require('../images/github-logo.png')} alt="GitHub" width={20} height={20} />
          </a>
        </div>
      </nav>
    </div>
  )
}

export default Home
